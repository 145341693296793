import React, { useEffect, useState } from "react"
import { useLocation } from "@reach/router"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import BlogHeader from "../../../components/Blog/header"
import { withTrans } from "../../../i18n/withTrans"
import { capitalize } from "@material-ui/core"
import BlogSectionList from "../../../components/Blog/sectionBlogList"
import i18next, { t } from "i18next"
import axios from "axios"
import { categoryMapping } from "../../../constants/blogCategoryList"
import "./style.scss"

const BlogCategory = () => {
  const location = useLocation()
  const searchParams = new URLSearchParams(useLocation().search)
  const [selectedTopic, setSelectedTopic] = useState(0)
  const [topics, setTopics] = useState([
    {
      name: t("blog.all"),
      defaultName: "",
      category: "",
    },
  ])

  const pageCategory = location.pathname.split("/")[2]

  async function getSubCategoryDatas() {
    const mapIdx = categoryMapping.map(e => e.key).indexOf(pageCategory)
    const temppCategory =
      i18next.language == "en"
        ? categoryMapping[mapIdx == -1 ? 0 : mapIdx].en
        : categoryMapping[mapIdx == -1 ? 0 : mapIdx].id
    const response = await axios.get(
      `${process.env.GATSBY_API_URL}/api/scpublic/article/subcategory`,
      {
        params: {
          language: i18next.language,
        },
      }
    )
    if (response.data) {
      const tempTopics = [
        {
          name: t("blog.all"),
          defaultName: "",
          category: "",
        },
      ]
      setTopics(
        tempTopics.concat(
          response.data.filter(e => e.category.toLowerCase() == temppCategory)
        )
      )
    }
  }

  useEffect(() => {
    getSubCategoryDatas()
  }, [i18next.language, pageCategory])

  return (
    <Layout>
      <Seo title={capitalize(pageCategory)} key={0}></Seo>
      <BlogHeader />
      <div className={`section-banner ${pageCategory.toLowerCase()}`}>
        <div className="title-desc">
          <div className="title">
            {t(`blog.category-page-${pageCategory.toLowerCase()}-title`)}
          </div>
          <p>{t(`blog.category-page-${pageCategory.toLowerCase()}-desc`)}</p>
        </div>
        <div className="label-list">
          {topics.map((e, i) => (
            <div
              key={i}
              className={i == selectedTopic ? "item active" : "item"}
              onClick={() => {
                setSelectedTopic(i)
              }}
            >
              {e.name}
            </div>
          ))}
        </div>
      </div>
      <BlogSectionList
        category={pageCategory}
        subCategory={
          selectedTopic == 0 ? null : topics[selectedTopic].defaultName
        }
        label={searchParams.get("hashtag")}
        useHeader={false}
      />
    </Layout>
  )
}

export default withTrans(BlogCategory)
