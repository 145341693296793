export const categoryMapping = [
  {
    key: "company",
    en: "company",
    id: "perusahaan",
  },
  {
    key: "industry",
    en: "industry",
    id: "industri",
  },
]
