import React from "react"
import { Typography } from "@material-ui/core"
import { navigate } from "gatsby"
import "./articleCard.scss"
import {
  getArticleDateFormat,
  getDefaultArticleCategory,
} from "../../helpers/stringHelper"

const ArticleCard = ({
  thumbnail,
  title,
  datePublish,
  subCategory,
  category,
  slug,
  directToDetail,
}) => {
  const navigateToDetail = ({ category, slug }) => {
    if (directToDetail) {
      navigate(`${slug}`)
    } else {
      navigate(`${getDefaultArticleCategory({ category: category })}/${slug}`)
    }
  }
  return (
    <div
      className="article-card"
      onClick={() => {
        navigateToDetail({ category: category, slug: slug })
      }}
    >
      <img src={thumbnail} className="thumb" />
      <div className="info">
        <Typography className="title">{title}</Typography>
        <Typography className="date-info">{`${subCategory} | ${getArticleDateFormat(
          datePublish
        )}`}</Typography>
      </div>
    </div>
  )
}

export default ArticleCard
