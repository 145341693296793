import React, { useEffect, useState } from "react"
import "./sectionBlogList.scss"
import axios from "axios"
import { Grid, Typography } from "@material-ui/core"
import ArticleCard from "./articleCard"
import CustomPagination from "../Pagination"
import i18next, { t } from "i18next"

const BlogSectionList = ({
  title,
  seeAllText,
  onSeeAllClick,
  category,
  subCategory,
  label,
  useHeader,
}) => {
  const [page, setPage] = useState(1)

  const [articleResponse, setArticleResponse] = useState({
    currentPage: page,
    totalPage: 0,
    totalDbItem: 0,
    items: [],
  })

  async function getArticleList() {
    const response = await axios.get(
      `${process.env.GATSBY_API_URL}/api/scpublic/article`,
      {
        params: {
          page: page,
          size: 6,
          category: category.toLowerCase(),
          subcategory: subCategory,
          label: label,
          language: i18next.language,
        },
      }
    )
    setArticleResponse(response.data)
  }

  useEffect(() => {
    getArticleList()
  }, [page, category, subCategory, label, i18next.language])

  return (
    <div className="section-blog-list">
      {useHeader && (
        <div className="header">
          <div className="title-desc">
            <Typography className="title">{title}</Typography>
          </div>
          <div
            className="see-all-button"
            style={{
              display: articleResponse.totalPage == 0 ? "none" : "block",
            }}
            onClick={onSeeAllClick}
          >
            {seeAllText}
          </div>
        </div>
      )}
      {label && (
        <div className="label-result-title">
          {`${t("blog.showing-label-result")} #${label}`}
        </div>
      )}
      <Grid
        container
        spacing={3}
        alignContent="center"
        justifyContent="space-between"
      >
        {articleResponse.items.map((e, i) => (
          <Grid item key={i} lg={6} xs={12}>
            <ArticleCard
              thumbnail={e.thumbnail}
              title={e.title}
              datePublish={e.publishedTime}
              subCategory={e.subcategory}
              category={e.category}
              slug={e.slug}
              directToDetail={!useHeader}
            />
          </Grid>
        ))}
      </Grid>

      {articleResponse.totalPage == 1 ? (
        <></>
      ) : (
        <div className="pagination">
          <CustomPagination
            pageCount={articleResponse.totalPage}
            page={page}
            onPageChange={(e, v) => {
              setPage(v)
            }}
          />
        </div>
      )}
    </div>
  )
}

export default BlogSectionList
